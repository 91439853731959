import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/SEO'

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404" />
      <div
        className={`four-oh-four-container flex items-start lg:items-center py-12 px-6 bg-no-repeat`}
        style={{ height: '60vh' }}
      >
        <div className={`max-w-lg mx-auto`}>
          <h1
            className={`text-6xl font-bold text-center text-charcoal font-mono`}
          >
            404
          </h1>
          <p className={`text-light-slate text-center text-2xl pb-32`}>
            That URL’s gone.
            <br />
            Or it never existed.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
